.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: thin;
}
.dev-body {
  background: #e94d58;
}
.prod-body {
  background: #fbb800;
}
.sub-head {
  width: 100%;
  font-size: 22px;
  font-weight: bold;
}
.routeHome {
  position: relative;
  top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-div {
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  flex-wrap: wrap;
  align-items: left;
}
.password-div {
  display: flex;
}

.password-div img {
  width: 26px;
  height: 26px;
  position: relative;
  top: 12px;
  cursor: pointer;
}
.login-div input {
  margin: 10px;
  border-radius: 29px;
  border: 0px;
  width: 15rem;
  height: 30px;
}
.login-div input::placeholder {
  font-weight: bold;
  opacity: 0.9;
}

.login-div input[type="text"],
input[type="password"] {
  font-weight: bold;
}

.login-div input[type="button"] {
  cursor: pointer;
}

.password-field {
  margin: 10px !important;
  border-radius: 29px;
  border: 0px;
  width: 20rem;
  height: 50px;
}

.password-field input {
  position: relative;
  top: -14px;
}

.noWeek {
  position: relative;
  top: 15rem;
}

.loginButton {
  margin: 10px !important;
  border-radius: 29px;
  border: 0px;
  width: 20rem;
  height: 30px;
  line-height: 0.8;
  width: 83%;
  border: 0px;
  padding: 6px;
  background: white;
  font-weight: 500;
  cursor: pointer;
}

.main-div {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
}

.button-css {
  cursor: pointer;
  border: 0px !important;
  background: lightblue;
}

.circle-div:hover {
  background: orange !important;
}

.circle-div {
  width: 20px;
  height: 20px;
  border-radius: 10px !important;
  background: rgb(248, 35, 35);
}

.sub-div {
  width: 45%;
  height: 100px;
  background-color: lightblue;
  display: flex;
  justify-content: center;
  margin: 3px;
}

.image-preview {
  display: flex;
  margin: 20px;
  height: 20rem;
  padding: 1rem;
  min-width: 15rem;
  border: 1px dashed black;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
}

.upload-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 20px 20px 20px;
}

.loading-div {
  margin-top: 25%;
}

.tcLabel {
  font-size: 15px;
  font-weight: 700;
  color: #7f7575;
}

.video-icon {
  margin-top: 25%;
  font-size: 100px;
}

.preview-data {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: 20px;
  margin-left: 0px !important;
}

.preview-main {
  display: flex;
}

.preview-div {
  display: flex;
  justify-content: space-between;
  margin: 4rem;
  position: absolute;
  width: 90%;
  bottom: 0px;
}

.upload-all-button {
  height: 8rem;
  width: 8rem;
  border-radius: 2rem;
}

.ant-btn-primary {
  color: black !important;
  background-color: white !important;
  border: 1px solid;
}
.edit-section {
  font-size: 23px;
  margin-bottom: 10px;
  position: absolute;
  right: 5px;
  top: 3px;
}
.edit-section svg {
  cursor: pointer;
  margin-right: 10px;
}

.ant-btn-primary:disabled {
  background-color: #82878882 !important;
  color: white !important;
}
.loginButton:disabled {
  cursor: not-allowed;
}
.uploadAll {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.add-week {
  width: 90%;
  border: 1px solid;
  margin-right: 10px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  padding: 3px;
}
.add-week span {
  /* color: blue; */
}

.add-week-select {
  width: 100%;
}
.model-card {
  display: flex;
  width: 100%;
  min-height: 500px;
}

.model-card .p-1 {
  width: 35%;
  margin-left: 10px;
}

.model-card .p-2 {
  width: 45%;
}
.copy-panel {
  max-height: 200px;
  overflow-y: scroll;
}
.copy-button {
  margin-bottom: 5px;
}
.model-card .p-3 {
  width: 20%;
}

.p-3 div {
  margin-bottom: 5px;
}
.week-overflow {
  max-height: 500px;
  overflow-y: scroll;
}

.input-section {
  padding: 10px;
}

.input-section input {
  margin-bottom: 10px;
  width: 70%;
}

.input-section .labelDiv {
  width: 30%;
  text-align: end;
  padding-right: 10px;
  line-height: 2;
}

.input-row {
  display: flex;
}

.labelDivR {
  width: 70%;
  text-align: start;
  line-height: 2;
}

.ant-popover {
  top: 100px !important;
}

.footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.ant-layout-sider {
  max-width: 100% !important;
}

.sider {
  width: 100% !important;
  min-width: 100% !important;
}

.copy-section {
  width: 100%;
  margin-bottom: 10px;
}

.image-preview1 {
  max-width: 27rem;
  max-height: 27rem;
  object-fit: contain;
}
.deleteIcon {
  float: right;
  top: 14px;
  position: relative;
}

.tc {
  position: relative;
  left: -17px;
  font-weight: 500;
  padding: 0px;
  width: 200px !important;
  margin: 0px !important;
  border-radius: 0px !important;
}
.rc {
  position: relative;
  top: -15px;
  padding-right: 41px;
}
.rc img {
  cursor: pointer;
}
.tc-row {
  padding: 0px;
}
