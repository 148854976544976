body {
  margin: 0;
  font-family: "Nunito";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Nunito Italic";
  src: local("Nunito Italic"),
   url("./fonts/Nunito-Italic-VariableFont_wght.ttf") format("truetype");
  }

  @font-face {
    font-family: "Nunito";
    src: local("Nunito"),
     url("./fonts/Nunito-VariableFont_wght.ttf") format("truetype");
    }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
